.App{
    height:100%;
    width:100%;
}

.AppBody{
    height:100%;
    padding-top: 65px;
}

.AppBodyQueryBarTitle {
  padding-left: 15px;
  padding-top: 10px;
  /* float: left; */
  background-color: #f2f2f2;
  /* margin-left: 40px; */
  height: 20px;
}

.AppBodyQueryBar{
    /* height:60px; */
    height: 50px;
    background-color: #f2f2f2;
    padding-bottom: 65px;
}

.AppBodyContent{
    height:calc(100% - 70px);
}

.AppBodyContentResults{
    float: left;
    width: 400px;
    height:100%;
    border-top: 1px solid silver;
}

.AppBodyContentMap{
    margin-left: 400px;
    height:100%
}

body {
    padding: 0;
    margin: 0;
  }
  
  html, body, #root, .App, .App2, .row{
    height: 100%;
    width: 100%;
    position: relative;
  }
  
  
  .nav{
    margin-top: 10px;
    font-family: 'Montserrat', helvetica, sans-serif;
    font-size: 14px;
  }
  
  .nav>li>a:hover, .nav>li>a:focus {
    text-decoration: none;
    background: #000000;
  }
  
  .navSelected{
    background: #000000;
  }
  
  .zzz.nav>li>a:hover, .zzz.nav>li>a:focus {
    text-decoration: none;
    background: #CFCFCF !important;
  }
  
  .navbar {
    margin-bottom: 1px;
    height: 64px;
  }
  .navbar-inverse {
    background-color: #4f5954;
  }
  
  .navbar-brand {
    float: left;
    font-size: 17px;
    height: 40px;
    padding:0px;
  }
  
  .navLogo{
    position: absolute;
    left: 5px;
    top: 13px;
    font-family: "Roboto","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #000000;
  }

  .SearchPanel{
    padding:15px;
    width:100%;
    overflow-x: hidden;
    /*height: 100%;*/
    height:calc(100% - 40px);
  }

  .SearchIcons{
    padding-top: 8px;
    cursor: pointer;
    color: white;
  }

  .ClearIcon{
    padding-top: 8px;
    cursor: pointer;
    color: white;
    position: absolute;
    top: 0px;
    right: 8px;
  }

  .ClearGeocode{
    position: absolute;
    padding:0px;
    top: 0px;
    /*left: 310px;*/
    right:4px;
    cursor: pointer;
    color: white;
  }

  #map{
    border-top: 1px solid silver;
    border-left: 1px solid silver;
  }

  textarea:focus, textarea.form-control:focus, input.form-control:focus, input[type=text]:focus, input[type=password]:focus, input[type=email]:focus, input[type=number]:focus, [type=text].form-control:focus, [type=password].form-control:focus, [type=email].form-control:focus, [type=tel].form-control:focus, [contenteditable].form-control:focus {
    box-shadow: inset 0 -2px 0 #5C7064;
  }

  select:focus, select.form-control:focus {
    box-shadow: inset 0 -2px 0 #5C7064;
  }
