.App {
  /*text-align: center;*/

}

body{
  padding-top: 65px;
}

.Map{
  position: absolute;
  top:43px;
  right:500px;
  left:0px;
  bottom:0px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.Search {
  position: absolute;
  top:63px;
  right:0px;
  bottom:5px;
  width:500px;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

body {
  padding: 0;
  margin: 0;
}




.nav{
  margin-top: 10px;
  font-family: 'Montserrat', helvetica, sans-serif;
  font-size: 14px;
}

.nav>li>a:hover, .nav>li>a:focus {
  text-decoration: none;
  background: #000000;
}

.navSelected{
  background: #000000;
}

.zzz.nav>li>a:hover, .zzz.nav>li>a:focus {
  text-decoration: none;
  background: #CFCFCF !important;
}

.navbar {
  margin-bottom: 1px;
  height: 64px;
}
.navbar-inverse {
  background-color: #4f5954;
}

.navbar-brand {
  float: left;
  font-size: 17px;
  height: 40px;
  padding:0px;
}

.navLogo{
  position: absolute;
  left: 5px;
  top: 13px;
  font-family: "Roboto","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
}

.SearchPanel{
  padding:15px;
  width:100%;
  overflow-x: hidden;
}

/*
.SearchText{
  width: 400px;
  padding: 5px;
  position:relative;
  background-color: #ffffff;
  border-radius: 3px !important;
  border: 1px solid rgba(0,0,0,0.2) !important;
  height:30px !important;
}

.SearchInput{
  
  font-size: 14px !important;
  
  margin-top: 5px;
}
*/


.SearchText{
  width: 400px;
  padding: 5px;
  position:relative;
}

.SearchInput{
  background-color: #ffffff;
  border-radius: 4px !important;
  border: 1px solid rgba(0,0,0,0.2) !important;
  font-size: 14px !important;
  height:30px !important;
  margin-top: 5px;
}


.SearchPagenation{
  height:20px;
  width:400px;
  color: lightgrey;
  font-size: 11px;
}

.glyphiconFeedback {
  position: relative;
  top: 5px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.SearchResults{
  /*position: absolute;*/
  /*top:120px;*/
  /*bottom:0px;*/
  overflow:auto;
  width:375px;
  padding-top: 25px;
}

.PermitActions{
  /*position: absolute;*/
  /*top:0px;*/
  padding:5px;
  /*bottom:0px;*/
  overflow:auto;
  width:375px;
  /*
  animation-name: example;
  animation-duration: 0.15s;
  animation-timing-function: ease-out;
  */
}



.PermitActionResults{
  position: relative;
  top:0px;
  bottom:0px;
  overflow:auto;
  width:100%;
}

.PermitSubActionResults{
  padding:5px;
  border: 1px solid rgb(92, 92, 92);
  border-radius: 5px;
}

.PermitSubActionCategory{
  padding:5px;
  font-size: 11px;
  background-color: #f1f1f1;
  border-radius: 5px;
}

.PermitSubActionSeparator{
  border-top: 1px solid rgb(192, 192, 192);
}

.PermitSubActionResultsContainer{
  padding:5px;
  margin: 0px 5px 0px 7px;
}

@keyframes example {
  from {margin-left:500px;}
  to {margin-left:0px;}
}

.PermitActionsTableHeading{
  display: table-cell;
  width:120px;
  background-color:#4f5954;
  color:#cacecc;
  border: 1px solid #FFFFFF;
  padding: 5px;
  border-radius: 3px;
  font-weight: bold; 
}

.PermitActionsTableCellValue{
  display: table-cell;
  background-color: #cacecc;
  border: 1px solid #FFFFFF;
  padding:5px;
  border-radius: 3px; 
}

.SearchResultsTable{
  display:table;  
  width:100%;
}

.SearchResultsTableRow{
  display:table-row;
}

.SearchResultsTableHeading{
  display: table-cell;
  text-indent: 5px;
  width:135px;
}

.SearchResultsTableHeadingNarrow{
  display: table-cell;
  text-indent: 5px;
  width:100px;
}

.SearchResultsTableValue{
  display: table-cell;
  padding: 0px 5px 0px 5px;
}

.SearchResult{
  border-bottom: 1px solid gray;
  padding:5px;
  margin: 0px;
  overflow-x: hidden;
}

.SearchResult ul{
  padding-left: 10px;  
}

.SearchResult:hover{
  /*background-color:#E2E2E2;*/
  background:linear-gradient(to right, #cacecc, #e3e5e4);
}

.HighlightedSearchResult{
  border-bottom: 1px solid gray;
  padding:5px;
  margin: 0px;
  /*background-color:#FFBCBC;*/
  background:linear-gradient(to right, #cacecc, #e3e5e4);
  overflow-x: hidden;
}

.HighlightedSearchResult ul{
  padding-left: 10px;  
}

.HighlightedSearchResult:hover{
  /*background-color:#A2CCFD;*/
  background:linear-gradient(to right, #cacecc, #e3e5e4);
}

a {
  color: #545454;
  text-decoration: none;
}

a:hover{
  color: #000000;
  text-decoration: underline;
}

.selectedCat{
  background-color: #000000;  
}

.inline { 
  display: inline-block;
  padding-right: 5px;
  white-space: nowrap;
}

.BasemapLayers{
  position: absolute;
  right: 10px;
  width: 120px;
  top:50px;
  height:140px;
  padding:5px;
  background-color: #FFFFFF;
  color: #313131;
  border: 2px solid rgba(0,0,0,0.2);
  background-clip: padding-box;
  border-radius: 3px; 
  opacity:0.95;
  z-index:999;
  font-family: "Roboto","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 12px;
  font-weight: bold;
}

.basemap{
  display:table;
}

.basemapItem{
  display: table-cell;
  vertical-align: middle;
  line-height: 25px;
}


/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v12/IQHow_FEYlDC4Gzy_m8fcude9INZm0R8ZMJUtfOsxrw.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v12/IQHow_FEYlDC4Gzy_m8fcrpHcMS0zZe4mIYvDKG2oeM.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v12/IQHow_FEYlDC4Gzy_m8fcjh33M2A-6X0bdu871ruAGs.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v12/IQHow_FEYlDC4Gzy_m8fchHJTnCUrjaAm2S9z52xC3Y.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v12/IQHow_FEYlDC4Gzy_m8fcoWiMMZ7xLd792ULpGE4W_Y.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215;
}

.HexbinLegend{
  position: absolute;
  right: 10px;
  width: 120px;
  top:210px;
  height:190px;
  padding:5px;
  background-color: #FFFFFF;
  color: #313131;
  border: 2px solid rgba(0,0,0,0.2);
  background-clip: padding-box;
  border-radius: 3px; 
  z-index:999;
  font-family: "Roboto","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 12px;
  font-weight: bold;
  opacity:0.95;
}

.PermitLegend{
  position: absolute;
  right: 10px;
  width: 120px;
  top:210px;
  height:200px;
  padding:5px;
  background-color: #FFFFFF;
  color: #313131;
  border: 2px solid rgba(0,0,0,0.2);
  background-clip: padding-box;
  border-radius: 3px; 
  z-index:999;
  font-family: "Roboto","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 12px;
  font-weight: bold;
  opacity:0.95;
}

.SearchArea{
  position: absolute;
  right: 50%;
  width: 100px;
  top:45px;
  height:20px;
  padding:5px;
  background-color: #dddddd;
  color: #313131;
  border: 2px solid rgba(0,0,0,0.2);
  background-clip: padding-box;
  border-radius: 3px; 
  z-index:999;
  font-family: "Roboto","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 12px;
  font-weight: bold;
  opacity:0.95;
  cursor: pointer;
  text-align: center;
}

.tooltipZ{
  z-index: 10000;
  font-family: "Roboto","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 10px;
  padding:5px;
  opacity:0.5;
}


.HexbinLegendItem{
  display:table;
  line-height: 0px;
}

.inlineCell{
  display: table-cell;
  vertical-align: middle;
}

.hexbinPalette{
  display:table;
  margin-top: 5px;
  margin: 10px auto 0px auto;
  cursor: pointer;
}


.legendTitle{
  border-bottom: solid;
  margin-bottom: 5px;
}

.Geocoder{
  position: absolute;
  right: 10px;
  width: 325px;
  top:6px;
  height:33px;
  padding-left:5px;
  padding-right:5px;
  background-color: white;
  color: #313131;
  border: 2px solid rgba(0,0,0,0.2);
  background-clip: padding-box;
  border-radius: 5px; 
  z-index:999;
  font-family: "Roboto","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 10px;
  font-weight: normal;
  opacity:0.95;
}

.panelToggle{
  position:absolute;
  right:5px;
  top: 15px;
  color: #CCC;
  z-index:10000;
  cursor: pointer;
}

.table{
  display:table;
}

.tableRow{
  display: table-row;
}

.tableItem{
  display: table-cell;
  padding:2px;
}

.tableAttrName{
  display: table-cell;
  padding:2px;
  width:60px;
}

.selectlist{
  margin-right:20px;
  padding-left: 20px;
  padding-right: 20px;
}


.grid{
  height: auto;
  /*max-height: 375px;*/
}

.wj-flexgrid{
  font-family: "Roboto","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 11px;
}

.wj-flexgrid .wj-grid-editor {
  font-size: 12px;
}

.wj-state-multi-selected {
  background-color: #4e5852;
  color: #fff;
}

.wj-state-selected {
  background-color: #a1aca4;
  color: #000;
}

.wj-header {
  background-color: #dbdfdc;
  color: #444;
  font-weight: bold;
}

.wj-content {
  border-radius: 0px;
}

input[type="checkbox"]:checked:after {
  background-color: #a1aca4;
  border-color: #4e5852;
}

.TableView{
  padding-top: 40px;
  border-top: 1px solid silver;
}

.TableQueryForm{
  padding-left:10px;
  margin-bottom:10px;
  font-size: 14px;
  font-weight: bold;
}

.TableQueryForm select{
  font-size: 14px;
  font-weight: normal;
}

.TableQueryFormVertical{
  margin-left: 20px;
    /* margin-top: 10px; */
    padding-top: 16px;
    font-size: 12px;
    font-weight: bold;
    overflow: hidden;
    /* padding-left: 25px; */
}

.TableQueryFormVerticalShow{
  animation-duration: 0.5s;
  animation-name: openFilter;
}

.TableQueryFormVerticalHide{
  animation-duration: 0.25s;
  animation-name: closeFilter;
}

.TableQueryFormVertical select{
  font-size: 12px;
  font-weight: normal;
  height: 30px;
  border-radius: 5px;
  border: 1px solid rgba(0,0,0,0.2);
}

.SearchNoResult{
  padding:10px;
  font-size: 14px;
  font-weight: bold;
}

.DownloadAndSummary{
  padding:3px 5px 0px 5px;
}

.Home{
  padding-left:10px;
  height: 100%;
}
.HomeTitle{
  font-family: 'Montserrat', helvetica, sans-serif;
  font-size: 18px;
}

.HomeWelcome{
  font-family: 'Montserrat', helvetica, sans-serif;
  font-size: 16px;
  border-bottom: 1px solid #777777;
}

.HomeWelcomeBottom{
  border-top: 1px solid #777777;  
}

.HomeTextContainer{
  height:calc(100% - 135px);
  overflow:auto;

}

.HomeText{
  padding-top:10px;
  padding-left: 10px;
}

.HomeSection{
  padding-top:10px;
}

.HomeHeadingText{
  font-family: 'Montserrat', helvetica, sans-serif;
  text-decoration: underline;
  font-size: 12px;
}

.ContactPanel{
  white-space: pre-line;
  padding-left:40px;
  padding-right: 5px;
}

.ContactInnerBox{
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: #a6a6a6;
  padding-left: 10px;
  width:100%;
}

.MapCell{
  color:#455E88;
  cursor: pointer;
  font-size: 14px;
}

.form-control::-ms-clear {display: none; width:0; height:0;}

.ZoomAll{
  color:#FFE600
}

.DropDownMenu{
  position: absolute;
  left:350px;
  top:10px;
  z-index: 10000; 
  background-color: transparent;
}

.tradeMark{
  display:inline;
  font-size: 0.4em;
}

.circleStyle {
  border: 2px solid #bbbbbb;
  height: 9px;
  border-radius:6px;
  width: 9px;
  line-height: 22px;
}

.leaflet-draw-actions .leaflet-draw-actions-top .leaflet-draw-actions-bottom{
  display: none !important;
}

.corpsmap .leaflet-bar a, .corpsmap .leaflet-bar a:hover, .corpsmap .leaflet-bar button, .corpsmap .leaflet-bar button:hover, .corpsmap .leaflet-bar div, .corpsmap .leaflet-bar div:hover{
  width:30px;
}


@keyframes openFilter {
  from {
    height:0px; 
  }

  to {
    height:90px;
  }
}

@keyframes closeFilter {
  from {
    height:90px; 
  }

  to {
    height:2px;
  }
}

/* Make it a marquee */
.marquee {
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  color:greenyellow;
  background-color: black;
}

.marquee span {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 20s linear infinite;
}

#customAlert {
  display: none;
  position: fixed;
  top: 12%;
  left: 85%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 20px;
  border-radius: 5px;
  z-index: 1000;
}

/* Make it move */
@keyframes marquee {
  0%   { transform: translate(0, 0); }
  100% { transform: translate(-100%, 0); }
}
